<template>
  <div
    v-if="closestEdge === 'top'"
    class="w-full border-t border-primary-500"
  />

  <div ref="element" class="border-b border-gray-200 w-full">
    <div
      :class="[
        'flex items-center w-full justify-between px-3 py-2 gap-2 border border-transparent',
        $attrs.class,
      ]"
    >
      <div ref="dragElement" class="cursor-grab">
        <IconDrag />
      </div>
      <UCheckbox v-model="state.checked" class="ml-1" />
      <FieldColorPicker :selected-color="state.color" @select="onColorChange" />
      <input
        ref="inputRef"
        v-model="state.label"
        required
        placeholder="Type your option name"
        class="outline-none text-sm w-full leading-6"
        maxlength="16"
        @keydown.enter.prevent.stop="onEnterPress"
        @keydown.backspace="onBackSpacePress"
      />
      <UButton
        v-if="options.length > 1"
        size="2xs"
        icon="i-heroicons-trash"
        color="gray"
        variant="ghost"
        @click="$emit('remove')"
      />
    </div>
  </div>
  <div
    v-if="closestEdge === 'bottom'"
    class="w-full border-b border-primary-500"
  />
</template>

<script lang="ts" setup>
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder'
import type { FieldOption } from '#field/types'
import type { FieldOptionColor } from '#field/constant'

const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
  dndKey: {
    type: String,
    required: true,
  },
  options: {
    type: Array as PropType<FieldOption[]>,
    required: true,
  },
  option: {
    type: Object as PropType<FieldOption>,
    required: true,
  },
})

const emit = defineEmits<{
  (e: 'change', event: typeof props.option): void
  (e: 'remove'): void
  (e: 'reorder', options: typeof props.options): void
  (e: 'enter'): void
  (e: 'backspace'): void
}>()

const element = ref()
const dragElement = ref()
const state = reactive(props.option)
const inputRef = ref<HTMLInputElement>()

const { closestEdge } = useDragAndDrop({
  dndKey: props.dndKey,
  axis: 'vertical',
  target: element,
  dragHandleTarget: dragElement,
  index: props.index,
  onDrop({ startIndex, finishIndex }) {
    const options = reorder({
      list: props.options,
      startIndex,
      finishIndex,
    })
    emit('reorder', options)
  },
})

const onEnterPress = () => {
  if (state.label === '') {
    return
  }

  emit('enter')
}

const onBackSpacePress = (e: Event) => {
  if (state.label === '') {
    e.preventDefault()
    emit('backspace')
  }
}

const onColorChange = (color: FieldOptionColor) => {
  state.color = color
}

watch(
  () => state,
  () => {
    emit('change', state)
  }
)
defineExpose({ focus: () => inputRef.value?.focus() })
</script>
