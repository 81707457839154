<template>
  <UForm
    ref="form"
    :schema="schema"
    :state="state"
    class="space-y-4"
    @submit="onSubmit"
  >
    <FormFieldBase v-model="state" />
    <UFormGroup name="options" label="Options">
      <div class="rounded-lg border border-gray-200 mt-1">
        <div :key="rerenderKey">
          <DndFieldCheckboxItem
            v-for="(option, index) in state.options"
            :ref="(ref) => (optionRefs[option.value] = ref as HTMLDivElement)"
            :key="option.value"
            :dnd-key="id"
            :option="option"
            :options="state.options"
            :index="index"
            :class="[
              'hover:ring-gray-400 ring-1 ring-transparent focus-within:!ring-primary-500 focus-within:relative',
              { 'rounded-t-lg': index === 0 },
            ]"
            @change="(value) => (state.options[index] = value)"
            @remove="onRemoveOption(index)"
            @reorder="onReorderOptions"
            @enter="onAddOption"
            @backspace="onOptionRemoved(index)"
          />
        </div>
        <div class="text-gray-700">
          <UButton
            size="sm"
            icon="i-heroicons-plus-small"
            color="gray"
            variant="ghost"
            :ui="{
              font: 'font-normal',
              padding: { sm: 'px-1 mx-1 py-1 my-1' },
            }"
            data-test="check-box-custom-add-option"
            @click="onAddOption"
          >
            Add option
          </UButton>
        </div>
      </div>
    </UFormGroup>
    <slot name="footer" />
  </UForm>
</template>

<script lang="ts" setup>
import { z } from 'zod'
import { cloneDeep } from 'lodash-es'
import type { FormSubmitEvent } from '#ui/types'
import type { Field } from '#field/types'
import { DEFAULT_FIELD_OPTION_COLOR, FieldOptionColor } from '#field/constant'

type FormValue = FormSubmitEvent<z.output<typeof schema>>

const props = defineProps({
  initialValue: {
    type: Object as PropType<Partial<Field>>,
  },
})

const emit = defineEmits<{
  (e: 'submit', event: z.output<typeof schema>, form: Ref): void
}>()

const id = uuid()
const schema = z.object({
  name: z.string().trim().min(1).max(16),
  options: z.array(
    z.object({
      label: z.string().trim().min(1).max(16),
      value: z.string(),
      checked: z.boolean(),
      color: z.string(),
    })
  ),
})

const normalizeInitialValue = computed(() => {
  const initialValue = cloneDeep(props.initialValue)
  if (initialValue?.options) {
    const defaultValue = JSON.parse(initialValue.default as string) as string[]
    let options = JSON.parse(
      initialValue.options as unknown as string
    ) as Field['options']
    options = options.map((option) => {
      return {
        ...option,
        checked: defaultValue.includes(option.value),
      }
    })
    extend(initialValue, {
      options,
    })
  }

  return initialValue
})

const form = ref()
const rerenderKey = ref(1)
const optionRefs = ref<Record<string, HTMLDivElement>>({})
const state = reactive({
  name: '',
  description: '',
  options: [
    {
      label: 'Option 1',
      checked: false,
      value: uuid(),
      color: FieldOptionColor.PRIMARY,
    },
  ],
  ...normalizeInitialValue.value,
})

const onAddOption = () => {
  const value = uuid()
  state.options.push({
    label: '',
    checked: false,
    value,
    color: makeNewColor(),
  })

  nextTick(() => {
    optionRefs.value?.[value]?.focus()
  })
}

const onRemoveOption = (index: number) => {
  state.options.splice(index, 1)
}

const onOptionRemoved = (index: number) => {
  onRemoveOption(index)
  const value = state.options?.[index - 1]?.value

  nextTick(() => {
    if (value) {
      optionRefs.value?.[value]?.focus()
    }
  })
}

const onReorderOptions = (options: typeof state.options) => {
  state.options = options
  rerenderKey.value++
}

const makeNewColor = () => {
  return (
    Object.values(FieldOptionColor)?.[
      state.options.length % Object.values(FieldOptionColor).length
    ] ?? DEFAULT_FIELD_OPTION_COLOR
  )
}

const onSubmit = (event: FormValue) => {
  const data = cloneDeep(event.data)
  if (data.options.length) {
    extend(data, {
      options: data.options.map((option) => ({
        label: option.label,
        value: option.value,
        color: option.color,
      })),
      default: JSON.stringify(
        data.options
          .filter((option) => option.checked)
          .map((option) => option.value)
      ),
    })
  }

  emit('submit', data, form.value)
}
</script>
